

























































































import {
  DtoUserUpdate,
  FormRules,
  updateUser,
  smsSend,
  reqArea,
  VoAreaList
} from "@/api";
import { Form } from "element-ui";
import { Component, Vue } from "vue-property-decorator";
import { Getter, State } from "vuex-class";
import { CascaderProps } from "node_modules/element-ui/types/cascader-panel";
import { isMobile, isEmail } from "@/utils/regexps";
interface CurrForm extends DtoUserUpdate, Record<string, string> {}

@Component({
  name: "change-user-info"
})
export default class extends Vue {
  @Getter("hasToken") hasToken!: boolean;
  @State(state => state.userInfo)
  userInfo!: any;
  form: CurrForm = {
    address: "",
    city: "",
    county: "",
    email: "",
    id: "",
    phoneNumber: "",
    phoneVerificationCode: "",
    province: "",
    sex: "",
    trueName: "",
    userName: ""
  };
  get cascaderProps(): CascaderProps<string, VoAreaList> {
    return {
      lazy: true,
      label: "name",
      value: "name",
      lazyLoad: async (node, resolve) => {
        const { data, level } = node;
        const { data: nodes } = await reqArea(level === 0 ? "0" : data.code);

        resolve(
          nodes.map(i => {
            return {
              ...i,
              leaf: level >= 2
            };
          })
        );
      }
    };
  }

  get areaChoose() {
    const { province, city, county } = this.form;
    return [province || "", city || "", county || ""];
  }

  set areaChoose(val: string[]) {
    this.form.province = val[0];
    this.form.city = val[1];
    this.form.county = val[2];
  }
  get rules(): FormRules<CurrForm> {
    return {
      userName: [
        {
          required: true,
          message: "请输入用户名",
          trigger: "blur"
        }
      ],
      trueName: [
        {
          required: true,
          message: "请输入真实姓名",
          trigger: "blur"
        }
      ],
      sex: [
        {
          required: true,
          message: "请选择性别",
          trigger: "blur"
        }
      ],
      email: [
        {
          required: true,
          message: "请输入正确的邮箱",
          trigger: "change",
          validator: (rule, value, callback) => {
            if (!isEmail(value)) {
              callback(new Error("请输入正确的邮箱"));
            } else {
              callback();
            }
          }
        }
      ],
      phoneNumber: [
        {
          required: true,
          message: "请输入正确的电话号码",
          trigger: "change",
          validator: (rule, value, callback) => {
            if (!isMobile(value)) {
              callback(new Error("请输入正确的电话号码"));
            } else {
              callback();
            }
          }
        }
      ]
    };
  }

  requesting = false;

  created(): void {
    if (this.hasToken) {
      this.form = JSON.parse(this.userInfo);
    }
  }

  async submitHandler() {
    try {
      this.requesting = true;
      await (this.$refs.form as Form).validate();
      await updateUser(this.form);
      this.$message.success("修改成功");
      this.$router.go(-1);
    } catch (error) {
      const { response } = error;
      this.$message.error(response.data.message);
    } finally {
      this.requesting = false;
    }
  }

  canSendCode = true;
  sendCodeTimeInterval: any;
  second = 60;
  buttonName = "发送验证码";
  async sendSmsCode() {
    if (!this.canSendCode) return;
    if (!this.form.phoneNumber) return this.$message.error("请先输入手机号");
    if (!isMobile(this.form.phoneNumber))
      return this.$message.error("请检查手机号是否正确");
    try {
      await smsSend(this.form.phoneNumber);
      this.canSendCode = false;
      this.sendCodeTimeInterval = setInterval(() => {
        this.buttonName = `${this.second}秒后重新发送`;
        --this.second;
        if (this.second < 0) {
          this.buttonName = "重新发送";
          this.second = 60;
          this.canSendCode = true;
          window.clearInterval(this.sendCodeTimeInterval);
          this.sendCodeTimeInterval = null;
        }
      }, 1000);
      this.$message.success("发送成功");
    } catch (error) {
      this.canSendCode = true;
      this.$message.error("发送失败");
    }
  }

  beforeDestroy(): void {
    window.clearInterval(this.sendCodeTimeInterval);
    this.sendCodeTimeInterval = null;
  }

  resetHandler() {
    this.form = {
      address: "",
      city: "",
      county: "",
      email: "",
      id: "",
      phoneNumber: "",
      phoneVerificationCode: "",
      province: "",
      sex: JSON.parse(this.userInfo).sex,
      trueName: JSON.parse(this.userInfo).trueName,
      userName: JSON.parse(this.userInfo).userName
    };
    (this.$refs.form as Form).clearValidate();
    (this.$refs.form as Form).resetFields();
  }
}
